import React, { useState } from "react"
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll'
import client01 from '../../../images/client/client01.png';
import tab01 from '../../../images/client/tab-client001.png';
import client02 from '../../../images/client/client02.png';
import tab02 from '../../../images/client/tab-client002.png';
import desk01 from '../../../images/client/desk01.png';
import desk02 from '../../../images/client/desk02.png';
import ReactMarkdown from "react-markdown"
import './Stories.scss';
import GetURL from '@Components/common/site/get-url';
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const fromLeft = {
    hidden: {
        opacity: 0,
        x: -40,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const fromRight = {
    hidden: {
        opacity: 0,
        x: 40,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const Motionsection = motion(Row)

const Stories = (props) => {
    const [videoId, setVideoId] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [videoindex, setVideoindex] = useState(null);
    const playVideo = (id, Embed_Video_URL) => {
        var videoid = getVideoId(Embed_Video_URL);
        setShowVideo(true)
        setVideoindex(id)
        setVideoId(videoid.id)
    }
    const closePlay = () => {
        setShowVideo(false)
        setVideoId('')
        setVideoindex(null)
    }
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }
    return (
        <React.Fragment>
            <InView threshold={0.2}>
                {({ ref, inView }) => {
                    return (
                        <Motionsection className="stories"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                        >
                            <Container>
                                <Row>
                                    <Col>
                                        <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                            <div className="stories-wrapper">
                                                <motion.div className="about-stories" variants={titleVariants}>
                                                    <span className="sm-heading">
                                                        {props.Team ? props.Title : "success stories"}
                                                    </span>
                                                    <h2>{props.Team ? props.Description : props.Title}</h2>
                                                    <ReactMarkdown source={props.Content} allowDangerousHtml />
                                                    {props?.Story_CTA?.CTA_1_Label && props?.Story_CTA?.CTA_1_URL &&
                                                        // <div className="content">
                                                            <GetURL label={props?.Story_CTA?.CTA_1_Label} alias={props?.Story_CTA?.CTA_1_URL?.Alias} class={"choose-us"} />
                                                        //     <span className="circle">
                                                        //     </span>
                                                        // </div>
                                                    }
                                                </motion.div>
                                                {props.select_customers.map((node, index) => (
                                                    <div className={index === 0 ? "client-wrapper order-md-2 order-lg-1" : "client-wrapper order-md-1 order-lg-2"}>
                                                        <motion.div className="v-blk" variants={fromRight}>
                                                            {showVideo && videoindex === index && <div onClick={(e) => { closePlay(e) }} className="close-iframe-btn"></div>}
                                                            <div className={index === 1 && !props.Team ? "success img-info client-img img-zoom" : index === 0 ? "client-img img-zoom" : "client-img img-info img-zoom"}>
                                                                {showVideo && videoindex === index &&
                                                                    <YouTube
                                                                        video={videoId}
                                                                        autoplay
                                                                        showRelatedVideos={false}
                                                                        showInfo={false}
                                                                        annotations={false}
                                                                        onEnd={e => { closePlay(e) }}
                                                                        modestBranding={1}
                                                                        onPlaying={trackerVideo(node?.Name)}

                                                                    />
                                                                }

                                                                <picture>
                                                                    <source media="(min-width:992px)" srcSet={node.Image.url} alt={node.Image.alternativeText} />
                                                                    <source media="(min-width:768px)" srcSet={node.Image.url} alt={node.Image.alternativeText} />
                                                                    <img src={node.Image.url} alt={node.Image.alternativeText} />
                                                                </picture>
                                                                {node.Embed_Video_URL &&
                                                                    <button className="video-buttons" onClick={(e) => {
                                                                        playVideo(index, node.Embed_Video_URL);
                                                                    }}>
                                                                        <strong className="video-btn" >
                                                                            <i className="icon-video">
                                                                            </i>
                                                                        </strong>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </motion.div>
                                                        <motion.div className="client-info" variants={fromLeft}>
                                                            <h2>{node.Name}</h2>
                                                            <span className="sm-text">{node.Designation}</span>
                                                        </motion.div>
                                                    </div>
                                                ))}
                                                {/* <div className="client-wrapper order-md-2 order-lg-1">
                                                    <div className="client-img img-zoom">
                                                        <picture>
                                                            <source media="(min-width:992px)" srcSet={desk01} alt="banner" />
                                                            <source media="(min-width:768px)" srcSet={tab01} alt="banner" />
                                                            <img src={client01} alt="client01" />
                                                        </picture>
                                                        <div className="video-buttons">
                                                            <strong className="video-btn" onClick={e => { setPlay(true) }}>
                                                                <i className="icon-video">
                                                                </i>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div className="client-info">
                                                        <h2>Alex Anderson</h2>
                                                        <span className="sm-text">Candidate</span>
                                                    </div>
                                                </div>
                                                <div className="client-wrapper order-md-1 order-lg-2">
                                                    <div className="client-img img-info img-zoom">
                                                        <picture>
                                                            <source media="(min-width:992px)" srcSet={desk02} alt="banner" />
                                                            <source media="(min-width:768px)" srcSet={tab02} alt="banner" />
                                                            <img src={client02} alt="client02" />
                                                        </picture>
                                                        <div className="video-buttons">
                                                            <strong className="video-btn " onClick={e => { setPlaying(true) }}>
                                                                <i className="icon-video ">
                                                                </i>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div className="client-info">
                                                        <h2>Emily Bailey</h2>
                                                        <span className="sm-text">Employer</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </ScrollAnimation>
                                    </Col>
                                </Row>
                            </Container>
                        </Motionsection>
                    )
                }}
            </InView>
        </React.Fragment>
    )
};

export default Stories;