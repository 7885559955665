import React, { useEffect, useState } from "react";
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll'
import img01 from '../../../images/contact/mob-img01.png';
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import client01 from '../../../images/image01.png';
import tab01 from '../../../images/contact/tab-img.png';
import desk01 from '../../../images/contact/desk-img.png';
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from "gatsby";
import EmployerForm from "@Components/forms/employer";
import CandidateForm from "@Components/forms/candidate";
import './Contact.scss';
import axios from "axios"
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from 'react-bootstrap/Modal'
const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const Contact = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [reviewid, setReviewid] = useState(false);
    const handleShow = (event) => {
        setReviewid(event);
        setShow(true);
    }
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
        autoplay:true,
        mobileFirst:true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                   
                }

            },
            {
              breakpoint:767,
               settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                     
                  }
          }
          ]
      };
    const formatter = buildFormatter(frenchStrings)
    const [testimonials, setTestimonials] = useState([])
    const [isClient, setClient] = useState(false);
    const getitems = async url => {
      try {
        const { data } = await axios.get(url, {
        })// could be from env files
        let newdata = data.filter(item => item.comment)
        setTestimonials(newdata)
      } catch (error) {
        // cache it if fail/error;
        console.error(error)
      }
    }
    useEffect(() => {
  
      let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
      getitems(url);
      setClient(true);
    }, [])
    const data = useStaticQuery(graphql`
    query{
        glstrapi {
            contactUs(publicationState: LIVE) {
                Content
                Title
                Image {
                  alternativeText
                  url
                }
              }
            }
        }
            `);

    return (
        <InView threshold={0.2}>
            {({ ref, inView }) => {
                return (
                    <motion.section className="contact-us"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <motion.div variants={contentVariants}>
                                <Row>
                                    <ScrollAnimation animateOnce={true} className="contact-left-blk" animateIn='fadeInLeft'>
                                    <div ref={props.myRefform} id="scroll_view"></div>
                                        <Col sm={12} lg={8} xl={12} className="p-xxl-0">
                                            <div className="contact">
                                                <sm className="sm-heading">
                                                    contact us
                                                </sm>
                                                <h2>{data?.glstrapi?.contactUs?.Title}</h2>
                                                <ReactMarkdown source={data?.glstrapi?.contactUs?.Content} allowDangerousHtml />
{/* {props.Title} */}
                                                <Tabs defaultActiveKey={props.Title === 'Candidates' || props.Title === 'Candidate Journey' ? "profile" : "home"}>
                                                    <Tab disabled={props.Title === 'Candidates' ? true : false} eventKey="home" title="I’m an Employer">
                                                        <div className="form-content">
                                                            <EmployerForm />
                                                        </div>
                                                    </Tab>
                                                    <Tab disabled={props.Title === 'Employers' ? true : false} eventKey="profile" title="I’m a Candidate" className="profile-info">
                                                        <div className="form-content">
                                                            <CandidateForm buttonYes={props.Title === 'Candidates' ? true : false} />
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </Col>
                                    </ScrollAnimation>

                                    <Col sm={12} lg={6} xl={4} className="ml-lg-auto">
                                    <div className="feedback">
                                        <ScrollAnimation animateOnce={true} animateIn='fadeInRight'>
                                            <picture className="img-zoom">
                                                <source media="(min-width:992px)" srcSet={data?.glstrapi?.contactUs?.Image?.url} alt={data?.glstrapi?.contactUs?.Image?.alternativeText} />
                                                <source media="(min-width:768px)" srcSet={data?.glstrapi?.contactUs?.Image?.url} alt={data?.glstrapi?.contactUs?.Image?.alternativeText} />
                                                <img src={data?.glstrapi?.contactUs?.Image?.url} alt={data?.glstrapi?.contactUs?.Image?.alternativeText} />
                                            </picture>
                                            {testimonials && testimonials.length > 0 &&
                                            <div className="feedback-content">
                                                <Slider {...settings}>
                                                {testimonials.slice(0, 6).map((node, i) => (
                                    <div className="brand-name">
                                                {node.starRating === 'FIVE' &&
                                                <ul className="rating">
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                </ul>
                                                }
                                                {node.starRating === 'FOUR' &&
                                                <ul className="rating">
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                </ul>
                                                }
                                                                                                {node.starRating === 'THREE' &&
                                                <ul className="rating">
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                </ul>
                                                }

{node.starRating === 'TWO' &&
                                                <ul className="rating">
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                </ul>
                                                }
                                                {node.starRating === 'ONE' &&
                                                <ul className="rating">
                                                    <li>
                                                        <i className="icon-star"></i>
                                                    </li>
                                                </ul>
                                                }
                                    {node.comment &&
                                    <p className="text-content">
                                    {node.comment && node.comment.substring(0, 200)}
                                                                {node.comment && node.comment.length > 200 ? '... ' : ''}
                                                                {node.comment && node.comment.length > 200 &&
                                                                    <a href="javascript:void(0);" className="read-more" onClick={() => handleShow(node.id)}>Read More</a>
                                                                }
                                      </p>
                                            }
                                                {reviewid && reviewid == node.id &&
                                                                <>
                                                                    <Modal
                                                                        show={show}
                                                                        size="lg"
                                                                        centered={true}
                                                                        onHide={handleClose}
                                                                        backdrop="static"
                                                                        keyboard={false}
                                                                        dialogClassName="modal-90w modal-form reviewPopup">
                                                                        <div className="review-wrap">
                                                                            <Modal.Header closeButton className="contact-close-btn">
                                                                                <Modal.Title className="w-100">{node.reviewer.displayName}
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                            <div className="reviews-box">
                                                                                <span className="review-date">
                                                                                    <TimeAgo date={node.createTime} formatter={formatter} />
                                                                                </span>
                                                                                {node.starRating === "FIVE" &&
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                                            }
                                    {node.starRating === "FOUR" &&
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                                            }
                                                                                <div className="review-desc">
                                                                                    {node.comment}
                                                                                </div>
                                                                                </div>
                                                                            </Modal.Body>
                                                                        </div>
                                                                    </Modal>
                                                                </>
                                                            }
                                                <div className="client-details">
                                                    <div className="clients-img">
                                                        <img src={node.reviewer.profilePhotoUrl} alt="img01" />

                                                    </div>
                                                    <div className="about-client">
                                                        <h5>
                                                        {node.reviewer.displayName}
                                                        </h5>
                                                        <span className="time-info">
                                                        <TimeAgo date={node.createTime} formatter={formatter} />
                                                        </span>
                                                    </div>
                                                </div>
                                                </div>
                                  ))}
                                  </Slider>
                                            </div>
            }
                                        </ScrollAnimation>
                                    </div>
                                </Col>
                                </Row>
                            </motion.div>
                        </Container>
                    </motion.section>
                )
            }}
        </InView>
    )
};
export default Contact;