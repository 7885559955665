import React, { useState, useRef } from "react"
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from "gatsby"
import team01 from '../../../images/team/team01.png';
import team02 from '../../../images/team/tab-team.png';
import team03 from '../../../images/team/desk-team.png';
import ReactMarkdown from "react-markdown"
import GetURL from '@Components/common/site/get-url';
import './Team.scss';
import CustomVideoPlay from "./CustomVideo.js";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { BLOGS_PAGE_URL } from "@Components/common/site/constants";
import ReactPlayer from "react-player";
import { VideoPause, VideoPlayButton } from '@Components/icon/icon';
import { inViewOptions } from '../../../Components/utils/animations';

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const Team = (props) => {
    const vidRef = useRef(null);
    const [showVideo, setShowVideo] = useState(false);
    const [isPlay, setPlay] = useState(false);
    if (props.Upload_Video !== null && props.Upload_Video !== "") {
        var video_url = props.Upload_Video?.url;
        video_url = video_url ? video_url : '';
    }
    const [showHTMLVideo, setHTMLVideo] = useState(true);
    const [muteVideo, setMuteVideo] = useState(true);
    const [play, setPlayvideo] = useState(true);
    const [controls, setVideoControls] = useState(false);
    const startplayvideo = (ref) => {

        setPlayvideo(true)
        // setVideoControls(false)
        setMuteVideo(false)
    }
    const showControls = () => {
        setVideoControls(true)
    }
    const pausevideo = (ref) => {
        setPlayvideo(false)
        setVideoControls(false)
        setMuteVideo(true)

    }
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => {
                return (
                    <motion.section className="our-team"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >

                        {/* {showHTMLVideo === true ? 'true' : 'false'}{play === true ? 'true' : 'false'}{controls === true ? 'true' : 'false'}{muteVideo === true ? 'true' : 'false'} */}
                        <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
                            <Container>
                                {showHTMLVideo && !muteVideo && <div onClick={() => pausevideo()} className="close-iframe-btn d-none d-md-block"></div>}
                                <motion.div className={showHTMLVideo && play && controls && muteVideo ? "about-team" : showHTMLVideo && play && !controls && muteVideo ? "about-team" : showHTMLVideo && !play && !controls && muteVideo ? "about-team" : props.Upload_Video && muteVideo ? "about-team" : "about-team none"} variants={contentVariants}>

                                    <span className="sm-heading">
                                        {props.Title}
                                    </span>
                                    <h2>{props.Description}</h2>
                                    <ReactMarkdown source={props.Content} allowDangerousHtml />
                                    {props.Add_CTA?.Label && props?.Add_CTA?.URL &&
                                        // <div className="content" href="#">
                                        <GetURL label={props?.Add_CTA?.Label} alias={props?.Add_CTA?.URL?.Alias} class={"choose-us"} />
                                        //     <span className="circle"></span>
                                        // </div>
                                    }
                                    {props.Add_CTA?.Label && props?.Add_CTA?.CTA_BLOG_URL?.URL &&
                                        <Link to={BLOGS_PAGE_URL.alias + '/' + props?.Add_CTA?.CTA_BLOG_URL?.URL} className="choose-us">{props?.Add_CTA?.Label}</Link>
                                    }
                                </motion.div>
                            </Container>

                            {showHTMLVideo && !muteVideo && <div onClick={() => pausevideo()} className="close-iframe-btn close-iframe-white-btn d-block d-md-none"></div>}

                            {props.Video_Url}
                            <div className={video_url ? "our-team video" : "our-team"} style={{ backgroundImage: `url(${props.Image?.url})` }}>
                                {props.Video_Url ?
                                    <CustomVideoPlay title={props.Title} url={props.Video_Url} />
                                    :

                                    // <CustomVideoPlay title={props.Title} url={props.Upload_Video?.url}/> 
                                    props.Upload_Video && muteVideo ?
                                        <div
                                            className="video-buttons"
                                            onClick={() => {
                                                setHTMLVideo(true); startplayvideo();
                                            }}
                                        >
                                            <strong className="video-btn ">
                                                <i className="icon-video "></i>
                                            </strong>
                                        </div>
                                        // <strong className="play-btn" onClick={() => {
                                        //   setHTMLVideo(true); startplayvideo();
                                        // }}><VideoPlayButton /></strong>
                                        :
                                        ''}

                                {showHTMLVideo && play && controls && !muteVideo &&
                                    <div className="video-pause">
                                        <a onClick={() => pausevideo()}>  <VideoPause />
                                        </a>
                                    </div>
                                }
                                {/* {props.Video && muteVideo ?
                                <strong className="play-btn" onClick={() => {
                                setHTMLVideo(true); startplayvideo();
                                }}><VideoPlayButton /></strong> : null} */}

                                {showHTMLVideo &&
                                    <div className="html-vid">
                                        <ReactPlayer autoplay ref={vidRef}

                                            url={video_url}
                                            muted={muteVideo}
                                            loop={true}
                                            controls={false}
                                            autoplay={true}
                                            playsinline={true}
                                            playing={play}
                                            onEnded={() => pausevideo()}
                                            onClick={() => showControls()}
                                        />
                                    </div>
                                }

                                <picture className="our-team-image">
                                    <source media="(min-width:992px)" srcSet={props.Image?.url} alt={props.Image?.alternativeText} />
                                    <source media="(min-width:768px)" srcSet={props.Image?.url} alt={props.Image?.alternativeText} />
                                    <img src={props.Image?.url} alt={props.Image?.alternativeText} />
                                </picture>
                            </div>
                        </ScrollAnimation>
                    </motion.section>
                )
            }}
        </InView>
    )
};

export default Team;