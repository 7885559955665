import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import PlayVideo from "../../Play/PlayVideo";
import Mob01 from "../../../images/trusted/mob-img.png"
import Mob02 from "../../../images/trusted/mob-img02.png"
import Mob03 from "../../../images/trusted/mob-img03.png"
import desk01 from "../../../images/advice/blog-1.jpg"
import desk02 from "../../../images/advice/blog-2.jpg"
import desk03 from "../../../images/advice/blog-3.jpg"
import ScrollAnimation from 'react-animate-on-scroll';
import "./Videos.scss"
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import { isMobile, isMobileTab } from '../../jsutility/index'
import ReactMarkdown from "react-markdown"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { useStaticQuery, graphql } from "gatsby";
import moment from "moment"
import NewsletterForm from "@Components/forms/newsletter-form";

import { inViewOptions } from '../../../Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 40,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.8
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const items = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 2
        }
    }
}

const MotionColumn = motion(Col)

const Videos = (props) => {
    const [videoId, setVideoId] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [videoindex, setVideoindex] = useState(null);
    const playVideo = (id, Embed_Video_URL) => {
        var videoid = getVideoId(Embed_Video_URL);
        setShowVideo(true)
        setVideoindex(id)
        setVideoId(videoid.id)
    }
    const closePlay = () => {
        setShowVideo(false)
        setVideoId('')
        setVideoindex(null)
    }
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }
    const data = useStaticQuery(graphql`
    query{
      glstrapi {
        videos(publicationState: LIVE,sort:"Date:desc", where: {Publish: true}) {
            Title
            Date
            Embed_Video_URL
            Tail_Image {
                alternativeText
                url
            }
      
        }
      }
    }
    `);
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section className="news-section advice-team"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <motion.div variants={titleVariants}>
                                    <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                        <div className="about-team">
                                            <h1 style={{display:"none"}}>Recruitment Videos</h1>
                                            <h2>
                                                {props.Title}
                                            </h2>
                                            <ReactMarkdown source={props.Content} allowDangerousHtml />
                                        </div>
                                    </ScrollAnimation>
                                </motion.div>
                            </Col>
                        </Row>
                        {/* <div className="news-box-wrap"> */}
                        {/* <ScrollAnimation animateOnce={true} animateIn='fadeIn'> */}
                        <div className="news-blog">
                            {data.glstrapi?.videos?.map((item, index) => (<>
                                <div className="blog-section">

                                    {/* {index === 6 ?
                                        <Row>
                                            <MotionColumn lg={12} variants={titleVariants}>
                                                <div className="subscribe">

                                                    <span className="sm-text">
                                                        <Link href="#" className="d-none d-lg-flex">
                                                            <i className="icon-contact"></i>
                                                        </Link>
                                                        Stay up to date with all of our latest news straight to your inbox
                                                    </span>
                                                    <NewsletterForm />
                                                    
                                                </div>
                                            </MotionColumn>
                                        </Row>

                                        : ""} */}
                                </div>

                                <div className="news-box">
                                    <MotionColumn variants={items}>
                                        {showVideo && videoindex === index && <div onClick={(e) => { closePlay(e) }} className="close-iframe-btn"></div>}
                                        <div className="properties-wrapper img-zoom">
                                            {/* <Link to={item.URL}> */}
                                            {showVideo && videoindex === index &&
                                                <YouTube
                                                    video={videoId}
                                                    autoplay
                                                    showRelatedVideos={false}
                                                    showInfo={false}
                                                    annotations={false}
                                                    onEnd={e => { closePlay(e) }}
                                                    modestBranding={1}
                                                    onPlaying={trackerVideo(item?.Title)}

                                                />
                                            }

                                            <picture>
                                                <source media="(min-width:1200px)" srcSet={item.Tail_Image.url} />
                                                <source media="(min-width:768px)" srcSet={item.Tail_Image.url} />
                                                <img src={item.Tail_Image.url} alt={item.Tail_Image.alternativeText} />
                                            </picture>
                                            {/* </Link> */}
                                            <div className="news-btn">
                                                <strong
                                                    className="video-btn "
                                                    onClick={(e) => {
                                                        playVideo(index, item.Embed_Video_URL);
                                                    }}
                                                >
                                                    <i className="icon-video "></i>
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="newsletter-info">
                                            {item.Date ? <div className="time-info">{moment(item.Date).format('DD MMMM YYYY')}</div> : ''}
                                            <h3>
                                                {" "}
                                                {item.Title}{" "}
                                            </h3>
                                        </div>
                                    </MotionColumn>
                                </div>
                            </>))}
                        </div>
                        {/* </ScrollAnimation> */}

                        {/* </div> */}

                    </Container>
                </motion.section>
            )}
        </InView>
    )
};

export default Videos