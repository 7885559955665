import React, { useState } from "react"
import { Nav, Button, Tabs, Tab, Form, Container, Row, Col, Accordion, Card } from "react-bootstrap";
// import AboutImg from "../../../images/advice/about.png";
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"

import { useStaticQuery, graphql } from "gatsby";

import { Link } from "gatsby"



import './QuestionList.scss';

const Candidates = (props) => {
    const [index, setIndex] = useState(null)
    const activeIndex = (i) => {
        if (index === i) {
            setIndex(null)
        }
        else {
            setIndex(i)
        }
    }
    const data = useStaticQuery(graphql`
    query{
    glstrapi {
        faqs(publicationState: LIVE, where: {Category: "Candidates", Publish: true}) {
          Question
          Answer
          Publish
          Category
        }
      }
    }
`);
    return (

        <Accordion defaultActiveKey="2">
        {data.glstrapi?.faqs?.map((item, i) => (
            <>
                <Accordion.Toggle onClick={(e) => { activeIndex(i) }} variant="link" eventKey={i + 1}>
                    <h4>{item.Question} <i className={`icon-more ${index === i ? "active" : ""}`}></i></h4>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={i + 1}>
                    <Card.Body><ReactMarkdown source={item.Answer} allowDangerousHtml /></Card.Body>
                </Accordion.Collapse>
            </>
        ))}
    </Accordion>
    )
};
export default Candidates;