import React, { useState } from "react"
import { Nav, Button, Tabs, Tab, Form, Container, Row, Col, Accordion, Card } from "react-bootstrap";
// import AboutImg from "../../../images/advice/about.png";
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby"
import Candidates from "./Candidates"
import Employers from "./Employers"
import Others from "./Others"
import './QuestionList.scss';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const QuestionList = (props) => {
    return (
        <InView threshold={0.2}>
            {({ ref, inView }) => {
                return (
                    <React.Fragment>
                        <motion.section className="question-list"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                        >
                            <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                <Container>
                                    <Row>
                                        <Col>
                                            <motion.div variants={contentVariants}>
                                                <div className="question">
                                                    <h1 style={{display:"none"}}>Career FAQ</h1>
                                                    <h2>{props.Title}</h2>
                                                    <ReactMarkdown source={props.Content} allowDangerousHtml />
                                                </div>

                                                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                                    <Tab eventKey="home" title="All FAQs">
                                                        <div className="details">
                                                            <h2>Candidates</h2>
                                                            <Candidates />
                                                        </div>
                                                        <div className="details">
                                                            <h2>Employers</h2>
                                                            <Employers />
                                                        </div>
                                                        {/* <div className="details">
                                                            <h2>Other</h2>
                                                            <Others />
                                                            
                                                        </div> */}
                                                    </Tab>
                                                    <Tab eventKey="profile" title="Employers">
                                                        <div className="details">
                                                            <h2>Employers</h2>
                                                            <Employers />
                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey="contact" title="Candidates">
                                                        <div className="details">
                                                            <h2>Candidates</h2>
                                                            <Candidates />

                                                        </div>
                                                    </Tab>
                                                    {/* <Tab eventKey="other" title="other">
                                                        <div className="details">
                                                            <h2>Others</h2>
                                                            <Others />

                                                        </div>

                                                    </Tab> */}
                                                </Tabs>
                                            </motion.div>
                                        </Col>
                                    </Row>
                                </Container>
                            </ScrollAnimation>
                        </motion.section>
                    </React.Fragment>
                )
            }}
        </InView>
    )
};

export default QuestionList;