import React, { useState, useEffect, useRef } from "react"
import { Button, Tabs, Tab, Form } from "react-bootstrap";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll'
import banner01 from '../../../images/mob-banner.png';
import banner02 from '../../../images/tab-banner.png';
import banner03 from '../../../images/desk-img.png';
import './Banner.scss';
import GetURL from '@Components/common/site/get-url';
import ReactMarkdown from "react-markdown"
import getVideoId from 'get-video-id';
import YouTube from '@u-wave/react-youtube';
import CommonSearchBanner from '@Components/predictive-search/common-search-banner'
import GoogleRating from "../../googleRating/GoogleRating";
import { getSrc } from "gatsby-plugin-image";
import SearchInputBoxJob from '@Components/predictive-search/search-inputbox-job'
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import CustomVideoPlay from "./CustomVideo.js";
import ReactPlayer from "react-player";
import { isMobileOnly } from "react-device-detect";
import { VideoPause, VideoPlayButton } from '@Components/icon/icon';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import { inViewOptions } from '../../../Components/utils/animations';

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const Banner = (props) => {
    const vidRef = useRef(null);
    const [showVideo, setShowVideo] = useState(false);
    const [isPlay, setPlay] = useState(false);
    if (props.Upload_Video !== null && props.Upload_Video !== "") {
        var video_url = props.Upload_Video?.url;
        video_url = video_url ? video_url : '';
    }
    const [showHTMLVideo, setHTMLVideo] = useState(true);
    const [muteVideo, setMuteVideo] = useState(true);
    const [play, setPlayvideo] = useState(true);
    const [controls, setVideoControls] = useState(false);
    const startplayvideo = (ref) => {

        setPlayvideo(true)
        // setVideoControls(false)
        setMuteVideo(false)
    }
    const showControls = () => {
        setVideoControls(true)
    }
    const pausevideo = (ref) => {
        setPlayvideo(false)
        setVideoControls(false)
        setMuteVideo(true)

    }
    const [videoId, setVideoId] = useState('');
    // const [showVideo, setShowVideo] = useState(false);

    const playVideo = () => {
        var videoid = getVideoId(props?.Embed_Video_URL);
        setVideoId(videoid.id)
        setShowVideo(true)
    }
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 450)
        })
    }, [])
    const [activeTab, setactiveTab] = useState('home')
    const handleSelect = (key) => {

        if (key === "profile") {
            props.executeScrollform()
            setactiveTab('home')
        }
        if (key === "home") {
            window.open("/for-candidates/job-search", "_self")
            setactiveTab('home')
        }

    }
    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Image_Transforms) {
        processedImages = props.imagetransforms.Image_Transforms;
    }
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => {
                return (
                    <motion.section className={showVideo ? "banner fullscreen" : props.Embed_Video_URL ? "banner video-banner" : props.Upload_Video ? "banner video-banner" : "banner"}
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
                            {showHTMLVideo && !muteVideo && <div onClick={() => pausevideo()} className="close-iframe-btn"></div>}
                            <div className="banner-img">
                                {showHTMLVideo &&
                                    <div className={showHTMLVideo && play && controls && muteVideo ? "html-vid" : showHTMLVideo && play && !controls && muteVideo ? "html-vid" : showHTMLVideo && !play && !controls && muteVideo ? "html-vid" : props.Upload_Video && muteVideo ? "html-vid" : "html-vid index-z-none"}>
                                        <ReactPlayer autoplay ref={vidRef}

                                            url={video_url}
                                            muted={muteVideo}
                                            loop={true}
                                            controls={false}
                                            autoplay={true}
                                            playsinline={true}
                                            playing={play}
                                            onEnded={() => pausevideo()}
                                            onClick={() => showControls()}
                                        />
                                    </div>
                                }
                                {showVideo &&
                                    <YouTube
                                        video={videoId}
                                        autoplay
                                        onEnd={e => { setShowVideo(false) }}
                                        modestBranding={1}
                                        onPlaying={trackerVideo(props.Title)}
                                        showRelatedVideos={false}
                                        showInfo={false}
                                        annotations={false}

                                    />
                                }
                                {/* <picture>
                                <source media="(min-width:992px)" srcSet={props.Banner_Image.url} alt={props.Banner_Image.alternativeText} />
                                <source media="(min-width:768px)" srcSet={props.Banner_Image.url} alt={props.Banner_Image.alternativeText} />
                                <div className="mob-banner">
                                <img media="(max-width:768px)" style={{height: props.Banner_Image.formats.large.height, width:props.Banner_Image.formats.large.width}} src={props.Banner_Image.url} alt={props.Banner_Image.alternativeText} />
                                </div>
                                <div className="non-mob-banner">
                                    <img media="(min-width:768px)" src={props.Banner_Image.url} alt={props.Banner_Image.alternativeText} />
                                    </div>
                            </picture> */}
                                {/* {props.Mobile_Banner_Image?.url} */}
                                {/* <ImageTransform imagesources={props.Mobile_Banner_Image?.url} renderer="srcSet" imagename='page.Mobile_Banner_Image.tile'
                                        attr={{ alt: props.Mobile_Banner_Image?.alternativeText, className: 'banner-img mobile-only' }}
                                        imagetransformresult={processedImages} id={props.id} >

                                    </ImageTransform> */}
                                <img className="banner-img desktop-device-only" src={getSrc(props.Banner_Image?.url_sharp)} alt={props.Banner_Image?.alternativeText} />
                                <img className="banner-img mobile-device-only" src={getSrc(props.Mobile_Banner_Image?.url_sharp)} alt={props.Mobile_Banner_Image?.alternativeText} />

                                {!showVideo &&
                                    <div className={showHTMLVideo && play && !controls && muteVideo ? "banner-content" : showHTMLVideo && !play && !controls && muteVideo ? "banner-content" : "banner-content none"}>
                                        {props.Embed_Video_URL ?
                                            <CustomVideoPlay title={props.Title} url={props.Embed_Video_URL} />
                                            : props.Upload_Video && muteVideo ?
                                                <div
                                                    className="video-buttons"
                                                    onClick={() => {
                                                        setHTMLVideo(true); startplayvideo();
                                                    }}
                                                >
                                                    <strong className="video-btn ">
                                                        <i className="icon-video "></i>
                                                    </strong>
                                                </div>
                                                : ''}
                                        {showHTMLVideo && play && controls && !muteVideo &&
                                            <div className="video-pause">
                                                <a onClick={() => pausevideo()}>  <VideoPause />
                                                </a>
                                            </div>
                                        }

                                        {/* {props.Embed_Video_URL && !showVideo &&
                                        <div
                                            className="video-buttons"
                                            onClick={e => { playVideo(true) }}
                                        >
                                            <strong className="video-btn ">
                                                <i className="icon-video "></i>
                                            </strong>
                                        </div>
                                    } */}
                                        {/* {showHTMLVideo === true ? 'true' : 'false'}
                                    {play === true ? 'true' : 'false'}
                                    {controls === true ? 'true' : 'false'}
                                    {muteVideo === true ? 'true' : 'false'} */}
                                        <motion.h1 variants={contentVariants}>
                                            {props.Title}
                                        </motion.h1>
                                        <ReactMarkdown source={props.Banner_Content} allowDangerousHtml />
                                        {props.Link_Type === 'Scrolldown' || props.Link_Type === 'Careers' ?
                                            <a onClick={props.Link_Type === 'Scrolldown' ? props.executeScrollform : props.executeScroll} className="btn btn-primary">{props.Banner_CTA_1_Label}</a>
                                            : ''}
                                        {props.Banner_CTA_1_URL && props.Banner_CTA_1_Label &&
                                            <GetURL label={props.Banner_CTA_1_Label} alias={props.Banner_CTA_1_URL?.Alias} class="btn btn-primary" />
                                        }
                                        {props.Show_Search ?
                                            <Tabs defaultActiveKey={activeTab} className="d-md-none" onSelect={(event) => handleSelect(event)}>
                                                <Tab eventKey="home" title="Find a Job">
                                                    <div className="form-content" className="d-md-none">
                                                        {/* <Form className="tab-form">
                                                        <Form.Control type="Text" placeholder="Job title, position or location" />
                                                        <Link href="#" className="search">
                                                            <i className="icon-search">
                                                            </i>
                                                        </Link>
                                                         <div className="input-field property-search_overlay">
                                                        <SearchInputBoxJob />
                                                        </div>
                                                        
                                                    </Form> */}
                                                        {
                                                            isMobileOnly &&
                                                            <CommonSearchBanner />
                                                        }
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="profile" title="Post a Job" className="profile-info">
                                                    <div className="form-content" className="d-md-none">
                                                        {/* <CommonSearchBanner /> */}
                                                    </div>
                                                    {/* <div className="tab-content">
                                                    <Form className="tab-form">
                                                        <Form.Control type="Text" placeholder="Job title, position or location" />
                                                        <Link href="#" className="search">
                                                            <i className="icon-search">
                                                            </i>
                                                        </Link>
                                                    </Form>
                                                </div> */}
                                                </Tab>
                                            </Tabs>
                                            : ''}
                                        {props.Show_Search && !scroll ?
                                            <div className="tab-content tablet d-none d-md-block">
                                                <CommonSearchBanner />
                                            </div>
                                            : ''}

                                    </div>
                                }

                                {props.Show_Review &&
                                <div className="rating-block d-none d-lg-block">
                                    <GoogleRating />
                                </div>
                            }
                            </div>
                        </ScrollAnimation>
                    </motion.section>
                )
            }}
        </InView>
    )
};

export default Banner;