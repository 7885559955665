import React, { useState } from "react"
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "gatsby"
import './BoxContent.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import bookIcon from "../../../images/book.svg";
import CameraIcon from "../../../images/camera.svg";
import ChatIcon from "../../../images/chats.svg";
import ReactMarkdown from "react-markdown"
import GetURL from '@Components/common/site/get-url';

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 40,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.8
        }
    }
}

const containerItem = {
    hidden: { opacity: 0, y: 50, scale: 0.8 },
    visible: {
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.8
        }
    }
}

const BoxContent = (props) => {
    const BoxList = [
        {
            IconName: bookIcon,
            BoxTitle: "Blogs",
            BtnText: "visit the blog",
            BoxText: "Lorem ipsum dolor sit amet conse ctetur adipiscing eli phasellus."
        },
        {
            IconName: CameraIcon,
            BoxTitle: "Videos",
            BtnText: "start watching",
            BoxText: "Lorem ipsum dolor sit amet conse ctetur adipiscing eli phasellus."
        },
        {
            IconName: ChatIcon,
            BoxTitle: "FAQs",
            BtnText: "find answers",
            BoxText: "Lorem ipsum dolor sit amet conse ctetur adipiscing eli phasellus."


        },

    ];
    return (
        <React.Fragment>
            <section className="box-content">
                    <Container>
                        <Row>
                            <Col className="d-lg-none">
                                {props.Services.map((item, index) => {
                                    var incekey =  index+1;
                                    var delaying = incekey+'00'; 
                                    return <>             
                                    <Accordion className="box">
                                        <ScrollAnimation delay={delaying} animateOnce={true} animateIn='fadeInUp'>
                                        <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                                            <div>
                                                <picture>
                                                    <source media="(min-width:992px)" srcSet={item?.Icon?.url} alt={item?.Icon?.alternativeText} />
                                                    <source media="(min-width:768px)" srcSet={item?.Icon?.url} alt={item?.Icon?.alternativeText} />
                                                    <img src={item?.Icon?.url} alt={item?.Icon?.alternativeText} />
                                                </picture>
                                                <span className="title">
                                                    {item.Title}
                                                </span>
                                            </div>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey={index + 1}>
                                            <Card.Body>
                                                <ReactMarkdown source={item.Content} allowDangerousHtml />
                                                {item?.CTA_Label && item?.CTA_URL?.Alias &&
                                                    <GetURL class={'btn btn-primary'} label={item?.CTA_Label} alias={item?.CTA_URL?.Alias} />
                                                }
                                            </Card.Body>
                                        </Accordion.Collapse>
                                        <i className="icon-right-slide d-lg-none"></i>
                                        </ScrollAnimation>
                                    </Accordion>
                                    </>
                                    })}
                            </Col>

                            {props.Services.map((item, index) => {
                                var incekey =  index+1;
                                var delaying = incekey+'00';          
                                return <>
                                <Col md={12} lg={4} className="d-none d-lg-block">
                                    <ScrollAnimation delay={delaying} animateOnce={true} animateIn='fadeInUp'>
                                    <div className="box">
                                        <picture>
                                            <source media="(min-width:992px)" srcSet={item?.Icon?.url} alt={item?.Icon?.alternativeText} />
                                            <source media="(min-width:768px)" srcSet={item?.Icon?.url} alt={item?.Icon?.alternativeText} />
                                            <img src={item?.Icon?.url} alt={item?.Icon?.alternativeText} />
                                        </picture>

                                        <span className="title">
                                            {item.Title}
                                        </span>
                                        <div className="box-details d-none d-lg-block">
                                            <ReactMarkdown source={item.Content} allowDangerousHtml />
                                            {item?.CTA_Label && item?.CTA_URL?.Alias &&
                                                <GetURL class={'btn btn-primary'} label={item?.CTA_Label} alias={item?.CTA_URL?.Alias} />
                                            }
                                        </div>
                                        <i className="icon-right-slide d-lg-none"></i>
                                    </div>
                                    </ScrollAnimation>
                                </Col>
                                </>
                            })}
                        </Row>
                    </Container>
            </section>
        </React.Fragment>
    )
};
export default BoxContent;